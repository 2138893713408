import React from 'react';

import config from '../../config';
import logo from "../assets/images/codefacto_white_1.svg"

export default function Footer() {
  return (
    <header id="header">
      <div className="logoContainer">
          <div className="logoWrapper">
            <img src={logo} alt="Codefacto Logo" />
          </div>
      </div>
      <p>Digital Product Development Agency</p>
      <nav>
        <ul>
          {config.socialLinks.map((social, i) => {
            const { icon, name, url } = social;
            return (
              <li key={i}>
                <a href={url} className={`icon ${icon}`}>
                  <span className="label">{name}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    </header>
  );
}
