module.exports = {
    siteTitle: 'Codefacto - Digital Product Developemnt Agency', // <title>
    manifestName: 'Codefacto',
    manifestShortName: 'WebSite', // max 12 characters
    manifestStartUrl: '/',
    manifestBackgroundColor: '#663399',
    manifestThemeColor: '#663399',
    manifestDisplay: 'standalone',
    manifestIcon: 'src/assets/images/cdf_icon.png',
    pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
    // social
  
    authorName: 'Andrea Ceran',
    authorHeading: 'Codefacto Founder & CEO, Front End Software Engineer',
    socialLinks: [
      {
        icon: 'fa-instagram',
        name: 'Instagram',
        url: 'https://www.instagram.com/codefacto/',
      },
      {
        icon: 'fa-twitter',
        name: 'Twitter',
        url: 'https://twitter.com/codefacto',
      }
    ],
  };
  